<template>
    <div>
      <title>Dino | v2</title>
      <section
        class="content-row content-row-color content-row-clouds content-row-half-height"
      >
        <div class="container">
          <div class="column-row align-center">
            <div class="column-33 text-align-center">
              <div class="loader-root" :style="`height: fit-content`">
                <span class="loader" :style="`height: ${size * 10.5}px; width: ${size * 10.5}px;  border-width: ${(size / 2) - 0.5}px; top: ${margin_top}px`"></span>
              </div>
            </div>
            <div class="column-50">
              <header
                class="content-header content-header-large content-header-align-left"
              >
                <h3>
                  <mark>Woah!</mark>
                </h3>
                <p>
                  We're very sorry but Dino v2 is not ready just yet!<br /><br />
                </p>  
                  <router-link class="button button-secondary" to="/discord"
                    >Join the Discord for updates<i class="fas fa-chevron-right icon-right"></i
                  ></router-link>
                  <p> </p>
              </header>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  <style scoped>
  .loader {
          
  border: 5px solid #003554;
  border-bottom-color: #0582CA;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  position: relative;
  top: 1.25px;
  }
      
  .loader-root {
  width: fit-content;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
      
    100% {
        transform: rotate(360deg);
      }
    }
    </style>
    <script>
      
    export default {
      
      created() {
        window.location.href = "#";
      },
      
    };
    </script>
    <script>
      export default {
        name: 'loadingSpinnerCenterLine',
          props: {
            size: {
                type: String,
                default: '24'
              },
             margin_top: {
              type: String,
              default: '0'
            }
        }
    }
  </script>
  